import "../Enums";
import {legacy}                 from "../../legacy";
import {diary}                  from "../../index";
import {GroupsPayments}         from "./GroupsPayments";
import {Pages}                  from "./Pages";
import ReactDOM                 from "react-dom";
import React                    from "react";
import {Comments}               from "../components/Comments";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";


export class GroupsDetail extends Pages {

    initPageHandlers() {
        //TODO обработка части выводимых блоков по старой схеме - почистить
        globObj["gID"] = $("#groupId").val();
        legacy.getDiaryTests();

        super.initPageHandlers();


        $(document).on("click", ".show_content", this.toggleSkips);

        $(document).on("change keyup input click", ".edit-student-payment", (event) => {
            let $clicked = $(event.target);
            event.preventDefault();
            diary.getDataInModal("student.payments", $clicked.attr("data-payments-detail"));
            diary.onLoadInModal = GroupsPayments.initInModalHandler;
        });

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"быстрый поиск по названию или ID группы"}
                entity={Entities.GROUPS}/>,
            document.getElementById('groups-search-form'),
        );

        this.initPageComponents();

    }

    toggleSkips(event) {
        let $clicked = $(event.currentTarget),
            $target, $buttonText;
        $target = $("#" + $clicked.data("target"));
        $buttonText = $clicked.find(".button_text");
        $target.toggle();
        if ($target.css("display") === "none") {
            $buttonText.html("показать пропуски");
        } else {
            $buttonText.html("скрыть пропуски");
        }

    }

    initPageComponents() {

        let commentsCells = document.querySelectorAll("[data-comments]");
        for (let i = 0; i < commentsCells.length; i++) {
            let element = commentsCells[i];
            ReactDOM.render(
                <Comments
                    load={true}
                    entityType={element.dataset.entityType}
                    entityId={element.dataset.entityId}
                />,
                element,
            );
        }
    }

    

}
